import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import MarkdownContent from "../components/MarkdownContent";
import SocialLinksSection from "../components/SocialLinksSection";

const AboutPage: React.FC<PageProps> = () => {
  const queryResult = useStaticQuery(graphql`
    query AboutQuery {
      file(sourceInstanceName: { eq: "pages" }, name: { eq: "about" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  const content = queryResult.file.childMarkdownRemark;
  return (
    <Layout>
      <SEO title="About" />
      <PageHeader title="About" />
      <MarkdownContent html={content.html} />
      <SocialLinksSection />
    </Layout>
  );
};

AboutPage.displayName = "AboutPage";

export default AboutPage;
