import React, { useState } from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

const HIDE_MESSAGE = "#hover-this-link-to-see";

const SocialLink: React.FC<SocialLinkProps> = ({
  url: originalUrl,
  text,
  icon,
  preventCrawler,
}) => {
  const [url, setUrl] = useState(preventCrawler ? HIDE_MESSAGE : originalUrl);
  return (
    <a
      className="flex items-center transition-colors duration-150 ease-in-out hover:text-red-400"
      href={url}
      onMouseEnter={preventCrawler ? () => setUrl(originalUrl) : undefined}
      onMouseLeave={preventCrawler ? () => setUrl(HIDE_MESSAGE) : undefined}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />
      <span className="ml-0.5 text-sm">{text}</span>
    </a>
  );
};

SocialLink.displayName = "SocialLink";

export default SocialLink;

export type SocialLinkProps = {
  url: string;
  text: string;
  icon: FontAwesomeIconProps["icon"];
  preventCrawler?: boolean;
};
