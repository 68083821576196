import React from "react";
import {
  faGithub,
  faTwitter,
  faFigma,
  faInstagram,
  faBehance,
  faUnsplash,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import SocialLink from "./SocialLink";

const SocialLinksSection: React.FC = () => (
  <section className="mt-8 pt-4 flex space-x-4 border-t border-gray-300 text-gray-700 dark:text-gray-50">
    <SocialLink
      url="https://github.com/chengluyu"
      text="chengluyu"
      icon={faGithub}
    />
    <SocialLink
      url="https://twitter.com/chengluyu"
      text="chengluyu"
      icon={faTwitter}
    />
    <SocialLink url="https://figma.com/@v" text="@v" icon={faFigma} />
    <SocialLink
      url="https://instagram.com/chengluyu"
      text="chengluyu"
      icon={faInstagram}
    />
    <SocialLink
      url="https://www.behance.net/chengluyu"
      text="chengluyu"
      icon={faBehance}
    />
    <SocialLink
      url="https://unsplash.com/@chengluyu"
      text="chengluyu"
      icon={faUnsplash}
    />
    <SocialLink
      url="mailto:luyu+website@hey.com"
      icon={faEnvelope}
      text="Email"
      preventCrawler
    />
  </section>
);

SocialLinksSection.displayName = "SocialLinksSection";

export default SocialLinksSection;
